<template>
  <div>
    <number-field
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :label="$t('t.StandingCreditLimit')"
      nullable
      :number.sync="computedStandingCreditLimit"
      :rules="rules"
    />
    <span class="mt-8 text--primary">{{$store.getters.currentUser.systemCurrency}}</span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    NumberField: () => import('@/components/number-field')
  },
  computed: {
    computedStandingCreditLimit: {
      get () { return this.dataStandingCreditLimit },
      set (v) {
        this.dataStandingCreditLimit = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      rules: [v => v >= 0 || this.$t('t.MustBeGreaterOrEqualTo', { value: 0 })],
      dataStandingCreditLimit: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'update-groupama-standing-credit-limit', standingCreditLimit: this.dataStandingCreditLimit }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean

  },
  watch: {
    document: {
      handler (v) {
        this.dataStandingCreditLimit = v?.standingCreditLimit ?? null
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
